import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from '../components/layout'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              date
              title
            }
            html
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  const { allMarkdownRemark: { edges }} = data
  return ( 
    <Layout>
      <h2>Test md files</h2>
      <ul>
        {edges.map(({ node }) => (
          <li key={node.fields.slug}>
            <h3><Link to={`/posts/${node.fields.slug}`}>{node.frontmatter.title}</Link></h3>
            <p>{node.frontmatter.date}</p>
          </li>
        ))}
      </ul>
    </Layout>
  )
 }